import React, { Component, useEffect, useState } from "react";

import AuthService from "../../services/auth.service";

import { withRouter } from "../../utils/with-router";

import ProductService from "../../services/product.service";

import WigglerService from "../../services/wiggler-service";
import UserService from "../../services/user.service";

import { Button, Container, Typography, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { CurrentUser } from "../../types/global";
import StockCard from "./stock-card/index.tsx";
import AddProduct, { NewProduct } from "./add-product/index.tsx";
import ProductCard from "./product-card/index.tsx";

type Product = {
  id: string;
  name: string;
  updated: boolean;
  command: string;
  cStock: number;
  nStock: number;
  image: string;
};

export const StockManager = ({ setLoading }: { setLoading: (loading: boolean) => void }) => {
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUser>();

  const [missing, setMissing] = useState<Product[]>([]);
  const [outOfStock, setOutOfStock] = useState<Product[]>([]);
  const [backInStock, setBackInStock] = useState<Product[]>([]);
  const [stockChange, setStockChange] = useState<Product[]>([]);
  const [showStockChange, setShowStockChange] = useState(false);
  const [showNewProducts, setShowNewProducts] = useState(false);
  const [newProducts, setNewProducts] = useState<NewProduct[]>([]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    //const currentUser = AuthService.getCurrentUser();
    AuthService.getCurrentUser().then((currentUser) => {
      setCurrentUser(currentUser);
      //UserService.syncUserStore(currentUser.id, currentUser.store_url)
      //this.getSDP(currentUser.id);
    });
  }, []);

  useEffect(() => {
    if (currentUser && !userReady) {
      setUserReady(true);
    }
  }, [currentUser]);

  const checkStock = () => {
    setMissing([])
    setOutOfStock([])
    setBackInStock([])
    setStockChange([])
    setNewProducts([])
    let _outOfStock:Product[] = [];
    let _backInStock:Product[] = [];
    let _stockChange:Product[] = [];
    let _missing:Product[] = [];
    let _new:NewProduct[] = [];
    setLoading(true);
    UserService.checkStock(currentUser?.id)
      .then((response) => {
        const differentProducts = response.differentProducts;
        const missingProducts = response.missingProducts;
        const newProducts = response.newProducts;

        for (const missingProduct of missingProducts) {
          let product: Product = {
            name: missingProduct.name,
            id: missingProduct.id,
            updated: false,
            image: "",
            cStock: missingProduct.stock_quantity,
            nStock: 0,
            command: "delete",
          };
          if (!product.name.toLowerCase().includes("personalised")) {
            _missing.push(product);
          }
        }

        setMissing(_missing);
        for (const differentProduct of differentProducts) {
          let product: Product = {
            name: differentProduct.updated.name,
            image: differentProduct.updated.image_1,
            cStock: differentProduct.current.stock_quantity,
            nStock: differentProduct.updated.stock,
            id: differentProduct.current.id,
            updated: false,
            command: "update",
          };

          if (differentProduct.updated.stock <= 0) _outOfStock.push(product);
          else if (differentProduct.current.stock <= 0 && differentProduct.updated.stock > 0)
            _backInStock.push(product);
          else _stockChange.push(product);
        }

        for (const newProduct of newProducts) {
          let product: NewProduct = {
            name: newProduct.name,
            images: [
              newProduct['URL Links'],
            ],
            id: newProduct.Barcode,
            sku: newProduct.sku,
            description: newProduct.description,
            price: newProduct.price,
            categories: newProduct.categories,
            dimensions: newProduct.dimensions,
            stock: newProduct.stock,
            material: newProduct.material,
          };
          _new.push(product);
        }

        setOutOfStock(_outOfStock);
        setBackInStock(_backInStock);
        setStockChange(_stockChange);
        setNewProducts(_new);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getProducts = () => {
    // get all wiggly products from database
    // compare each product's stock against SDP
    // if different, woo update product stock
    // if woo product not in SDP, move to trash
    // have editable datagrid to update product (sale price, categories etc)
    // update the database with woocommerce products,
    // start of day maybe or every few hours depending on resource usage
  };

  const deleteProduct = (product) => {
    setLoading(true);
    ProductService.deleteProduct(currentUser?.id, product.id).then((response) => {
      console.log(response);
      const newMissing = [...missing];
      const index = newMissing.findIndex((p) => p.id === product.id);

      newMissing[index].updated = true;

      setMissing(newMissing);
      setLoading(false);
    });
  };

  const updateProduct = (product) => {
    console.log(product);
    setLoading(true);
    const data = { stock_quantity: product.nStock };

    ProductService.updateProduct(currentUser?.id, product.id, data).then((response) => {
      let newState: Product[];

      console.log(response);
      let index = 0;
      if (product.nStock == 0) {
        newState = outOfStock;
        index = newState.findIndex((p) => p.id === product.id);
        newState[index].updated = true;

        setOutOfStock(newState);
      } else if (product.cStock == 0) {
        newState = backInStock;
        index = newState.findIndex((p) => p.id === product.id);
        newState[index].updated = true;

        setBackInStock(newState);
      } else {
        newState = stockChange;
        index = newState.findIndex((p) => p.id === product.id);
        newState[index].updated = true;

        setStockChange(newState);
      }
      setLoading(false);
    });
  };

  const [selectedProduct, setSelectedProduct] = useState<NewProduct>();
  const [index, setIndex] = useState(0);

  const addProduct = (product) => {
    setSelectedProduct(product);
    const index = newProducts.findIndex((prod) => prod.id === product.id);
    setIndex(index);
  };

  const addNextProduct = () => {
    if (!selectedProduct) return;

    const nextProduct = newProducts[index + 1];
    setSelectedProduct(nextProduct);
    setIndex(index + 1);
  };

  const batchDelete = () => {
    if (!missing) return;
    setLoading(true);

    const chunks: Product[][] = [];
    const chunkSize = 100;

    for (let i = 0; i < missing.length; i += chunkSize) {
      const chunk = missing.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const updatePromises = chunks.map((chunk) => {
      const products = chunk.map((product) => product.id);
      const data = {
        delete: products,
      };
      console.log({ data });
      return ProductService.batchUpdateProducts(currentUser?.id, data);
    });
    Promise.all(updatePromises)
      .then((responses) => {
        console.log(responses);
        setLoading(false);
        setMissing([]);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const batchUpdateOutOfStock = () => {
    if (!outOfStock) return;
    setLoading(true);

    const chunks: Product[][] = [];
    const chunkSize = 100;

    for (let i = 0; i < outOfStock.length; i += chunkSize) {
      const chunk = outOfStock.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const updatePromises = chunks.map((chunk) => {
      const products = chunk.map((product) => ({ id: product.id, stock_quantity: product.nStock }));
      const data = {
        update: products,
      };
      console.log({ data });
      return ProductService.batchUpdateProducts(currentUser?.id, data);
    });
    Promise.all(updatePromises)
      .then((responses) => {
        console.log(responses);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const batchUpdateStockChange = () => {
    if (!stockChange) return;
    setLoading(true);

    const chunks: Product[][] = [];
    const chunkSize = 100;

    for (let i = 0; i < stockChange.length; i += chunkSize) {
      const chunk = stockChange.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const updatePromises = chunks.map((chunk) => {
      const products = chunk.map((product) => ({ id: product.id, stock_quantity: product.nStock }));
      const data = {
        update: products,
      };
      console.log({ data });
      return ProductService.batchUpdateProducts(currentUser?.id, data);
    });
    Promise.all(updatePromises)
      .then((responses) => {
        console.log(responses);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const batchAddNewProducts = () => {
    if (!newProducts?.length) return;
    setLoading(true);

    const chunks: NewProduct[][] = [];
    const chunkSize = 100;

    for (let i = 0; i < newProducts.length; i += chunkSize) {
      const chunk = newProducts.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    const updatePromises = chunks.map((chunk) => {
      const products = chunk;
      console.log('bulk adding products')
      console.log({ products })
      return ProductService.batchAddProducts(currentUser?.id, products);
    });
    Promise.all(updatePromises)
      .then((responses) => {
        console.log(responses);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Container>
      {selectedProduct ? (
        <AddProduct
          selectedProduct={selectedProduct}
          currentUser={currentUser}
          cancel={() => setSelectedProduct(undefined)}
          nextProduct={addNextProduct}
          setSelectedProduct={setSelectedProduct}
          total={newProducts.length}
          index={index}
        />
      ) : (
        <div className="stock-check product-item-container">
          <Button variant="contained" onClick={() => checkStock()}>
            Get Latest Products
          </Button>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="h4">Expired Products</Typography>
          <Button onClick={batchDelete}>Trash All</Button>
          <Box id="products">
            {missing?.map((product, index) => {
              return <StockCard key={index} {...product} onClick={deleteProduct} command="Trash" />;
            })}
          </Box>

          <Typography variant="h4">Out of Stock</Typography>
          <Button onClick={batchUpdateOutOfStock}>Update All</Button>
          <Box id="products">
            {outOfStock?.map((product, index) => {
              return <StockCard key={index} {...product} onClick={updateProduct} command="Update" />;
            })}
          </Box>

          <Typography variant="h4">Back in Stock</Typography>
          <Button>Update All</Button>
          <Box id="products">
            {backInStock?.map((product, index) => {
              return <StockCard key={index} {...product} onClick={updateProduct} command="Update" />;
            })}
          </Box>

          <Typography variant="h4">New Products</Typography>
          <Typography>{newProducts.length} new products found</Typography>
          <Button onClick={() => setShowNewProducts(!showNewProducts)}>Show all new products</Button>
          <Button onClick={batchAddNewProducts}>Add All</Button>
          {showNewProducts && (
            <Box id="products">
              {newProducts?.map((product, index) => {
                return <ProductCard key={index} {...product} onClick={addProduct} command="Add" />;
              })}
            </Box>
          )}

          <Typography variant="h4">Stock Change</Typography>
          <Typography>{stockChange.length} products found with different stock levels</Typography>
          <Button onClick={() => setShowStockChange(!showStockChange)}>Show stock change products</Button>
          <Button onClick={batchUpdateStockChange}>Update All</Button>
          {showStockChange && (
            <Box id="products">
              {stockChange?.map((product, index) => {
                return <StockCard key={index} {...product} onClick={updateProduct} command="Update" />;
              })}
            </Box>
          )}
        </div>
      )}
    </Container>
  );
};

export default withRouter(StockManager);
