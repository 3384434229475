import { Container, Box, Grid, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import AlertDialog from "../../../components/dialog";
import SnackBar from "../../../components/snackBar";
import AddProductHeader from "./header/index.tsx";
import AddProductDescription from "./description/index.tsx";
import { CurrentUser, ReactImageGalleryItem } from "../../../types/global";
import AddProductCategories from "./categories/index.tsx";
import productService from "../../../services/product.service.js";
import AddProductTags from "./tags/index.tsx";

export type SDCategory = {
  id: string;
  code: string;
  name: string;
};

export type NewProduct = {
  id: string;
  name: string;
  images: string[];
  description: string;
  categories: SDCategory[];
  sku: string;
  price: string;
  dimensions: string;
  stock: number;
  material: string;
};


export default function AddProduct({
  currentUser,
  selectedProduct,
  index,
  total,
  cancel,
  nextProduct,
  setSelectedProduct,
}: {
  currentUser: CurrentUser;
  selectedProduct: NewProduct;
  index: number;
  total: number;
  setSelectedProduct: (product: NewProduct) => void;
  cancel: () => void;
  nextProduct: () => void;
}) {
  // snackbar
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("");
  const [snackLink, setSnackLink] = useState("");
  const [snackPath, setSnackPath] = useState("");

  const [galleryImages, setGalleryImages] = useState<ReactImageGalleryItem[]>([]);

  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (!selectedProduct) return;

    let _galleryImages: ReactImageGalleryItem[] = [];
    if (selectedProduct.images) {
      for (const img of selectedProduct.images) {
        if (img != "") {
          var url = img.split("?")[0];
          const image = {
            original: url,
            thumbnail: url,
            edited: false,
          };
          _galleryImages.push(image);
        }
      }
      setGalleryImages(_galleryImages);
    }
    modifyDescription(selectedProduct.description);
  }, [selectedProduct.id]);

  function modifyDescription(description) {
    const dimensionsStrings = description.split("Product Dimensions:");
    if (dimensionsStrings.length < 2) return description;

    const modifiedDescription = dimensionsStrings[0].trim();
    const dimensions = dimensionsStrings[1].split("Packaged Dimensions:");

    // Split the Product and Packaged Dimensions separately
    const productDimensions = dimensions[0].trim();
    const packagedDimensions = dimensions[1]?.trim();

    // Split the Product Dimensions by line breaks and remove duplicates
    const uniqueProductDimensions = [...new Set(productDimensions.split("\n").map((line) => line.trim()))].join("\n");

    // Reconstruct the modified description
    let modifiedText = modifiedDescription;
    if (uniqueProductDimensions) {
      modifiedText += `\n\nProduct Dimensions:${uniqueProductDimensions}`;
    }
    if (packagedDimensions) {
      modifiedText += `\nPackaged Dimensions:${packagedDimensions}`;
    }

    setDescription(modifiedText);
  }

  const handleAddProduct = () => {
    productService.addProduct(currentUser.id, selectedProduct, selectedCategories).then((response) => {
      console.log({ response });
      if (response && response.data) {
        if (response.data.id) {
          setSnackSeverity("success");
          setSnackMessage("Product added successfully, id: " + response.data.id);
          setSnackOpen(true);
          nextProduct();
        }
      }
    });
  };

  const setDescription = (description) => {
    if (!selectedProduct) return;

    const updatedProduct = {
      ...selectedProduct,
      description: description,
    };

    setSelectedProduct(updatedProduct);
  };

  return (
    <Container>
      <Box>
        <AddProductHeader index={index} total={total} selectedProduct={selectedProduct} />
        <Grid sx={{ my: 3 }} container spacing={2}>
          <Grid item md={5} xs={12}>
            <Box>
              <Box sx={{ minHeight: { md: "400px", xs: "200px" } }}>
                <ImageGallery
                  items={galleryImages}
                  showPlayButton={false}
                  showThumbnails={galleryImages.length > 1 ? true : false}
                />
              </Box>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Button onClick={handleAddProduct}>Add Product</Button>
                <Button onClick={nextProduct}>Next Product</Button>
                <Button onClick={cancel}>Cancel</Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <AddProductDescription description={selectedProduct.description} setDescription={setDescription} />
            <AddProductCategories
              currentUser={currentUser}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedProduct={selectedProduct}
            />
            <AddProductTags selectedProduct={selectedProduct} />
          </Grid>
        </Grid>
      </Box>
      <SnackBar
        snackOpen={snackOpen}
        message={snackMessage}
        severity={snackSeverity}
        link={snackLink}
        path={snackPath}
        onClose={() => setSnackOpen(false)}
      />
    </Container>
  );
}
